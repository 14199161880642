import { Button } from "@ui/Button";
import { FieldSelect } from "@ui/Field/FieldSelect/FieldSelect";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Root } from "./styles";
import { IFormSelectProps } from "./types";

function IndicatorsContainer() {
    return null;
}

const FormSelectRenderFn: ForwardRefRenderFunction<any, IFormSelectProps> = (props, ref) => {
    const { buttonProps, components, formElementProps, wrapperProps, ...restProps } = props;

    return (
        <Root {...wrapperProps}>
            <FieldSelect
                {...restProps}
                components={components}
                controlProps={{ className: "input-control" }}
                formElementProps={{
                    ...formElementProps,
                    components: {
                        IndicatorsContainer,
                        ...(formElementProps?.components || {}),
                    },
                }}
                hideLabel
                ref={ref}
            />
            <Button
                disabled={formElementProps?.isDisabled}
                type="submit"
                variant={formElementProps?.invalid ? "danger" : "secondary"}
                {...buttonProps}
                className="button"
                startIcon="search"
                shadow={false}
            >
                {props.label}
            </Button>
        </Root>
    );
};

export const FormSelect = forwardRef<any, IFormSelectProps>(FormSelectRenderFn);

FormSelect.displayName = "FormSelect";
