import { CssHelper } from "@utils/CssHelper";
import { mediaMin } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { RootProps } from "./types";

export const Root = styled("div")<RootProps>(
    ({ theme, borderColor, buttonWidth = 140, hideBorder, shadow = true, size = "large" }) => css`
        align-items: stretch;
        border-radius: ${rem(theme.borderRadius.default)};
        box-shadow: ${shadow ? theme.shadow.glow : null};
        display: flex;
        height: ${size ? CssHelper.value(theme.size.input[size]) : null};

        .input-control {
            flex: 1 1 auto;
            height: inherit;

            .input-component {
                border-bottom-right-radius: 0;
                border-color: ${!hideBorder && borderColor
                    ? theme.color.palette[borderColor]
                    : theme.color.palette.white};
                border-top-right-radius: 0;
                height: inherit;
                text-align: left;
            }
        }

        && {
            .button {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                flex: 0 0 auto;
                height: inherit;
                width: ${rem(70)};

                ${mediaMin(theme.breakpoint.sm)} {
                    width: ${rem(buttonWidth)};
                }

                & > .inner-component {
                    & > .start-icon {
                        margin-right: 0;

                        ${mediaMin(theme.breakpoint.sm)} {
                            display: none;
                            margin-right: ${rem(theme.legacySpacing(1.5))};
                        }
                    }

                    & > .text {
                        left: 0;
                        position: absolute;
                        text-indent: -9999px;
                        top: 0;

                        ${mediaMin(theme.breakpoint.sm)} {
                            position: static;
                            text-indent: 0;
                        }
                    }
                }
            }
        }
    `,
);
