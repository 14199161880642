import { FOCUS_VISIBLE_CLASSNAME } from "@styles/constants";
import { TextSmall } from "@ui/Text";
import { composeRefs } from "@utils/composeRefs";
import { withUnit } from "@uxf_base/utils/styling";
import clsx from "clsx";
import rem from "polished/lib/helpers/rem";
import React, { forwardRef, ForwardRefRenderFunction, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { AutoCompleteWrapper, Label, Root, StyledAutoComplete } from "./styles";
import { IFieldSelectProps } from "./types";

const FieldSelectRenderFn: ForwardRefRenderFunction<any, IFieldSelectProps> = (props, ref) => {
    const theme = useTheme();
    const innerRef = useRef<any>(null);

    const [isFocused, setIsFocused] = useState<boolean>();
    const {
        components,
        controlProps,
        CustomErrorComponent,
        formElementProps,
        id,
        inputProps,
        hideLabel,
        label,
        fluid,
    } = props;

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        if (formElementProps?.onFocus) {
            formElementProps.onFocus(e);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        if (formElementProps?.onBlur) {
            formElementProps.onBlur(e);
        }
    };

    return (
        <Root
            aria-disabled={formElementProps?.isDisabled}
            aria-invalid={formElementProps?.invalid}
            aria-readonly={formElementProps?.readOnly}
            fluid={fluid}
            {...controlProps}
        >
            {!!label && (
                <Label htmlFor={id} visuallyHidden={!!hideLabel}>
                    {label}
                </Label>
            )}
            <AutoCompleteWrapper
                size="default"
                {...inputProps}
                aria-disabled={formElementProps?.isDisabled}
                aria-invalid={formElementProps?.invalid}
                aria-readonly={formElementProps?.readOnly}
                className={clsx("input-component", isFocused && FOCUS_VISIBLE_CLASSNAME, inputProps?.className)}
            >
                <StyledAutoComplete
                    id={id}
                    ref={composeRefs(innerRef, ref)}
                    {...formElementProps}
                    menuStyles={{
                        marginLeft: rem(-theme.legacySpacing(2)),
                        marginRight: rem(-theme.legacySpacing(2)),
                        marginTop: withUnit(theme.border.default, "px"),
                        width: `calc(100% + ${rem(theme.legacySpacing(4))})`,
                        ...(formElementProps?.menuStyles ?? {}),
                    }}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    components={components}
                />
            </AutoCompleteWrapper>
            {CustomErrorComponent ? (
                <CustomErrorComponent
                    aria-invalid={formElementProps?.invalid}
                    aria-errormessage={formElementProps?.errorMessage}
                    value={formElementProps?.value}
                />
            ) : (
                !!formElementProps?.invalid &&
                !!formElementProps?.errorMessage && (
                    <TextSmall as="div" mt={theme.legacySpacing(0.5)} textColor="danger">
                        {formElementProps.errorMessage}
                    </TextSmall>
                )
            )}
        </Root>
    );
};

export const FieldSelect = forwardRef<any, IFieldSelectProps>(FieldSelectRenderFn);

FieldSelect.displayName = "FieldSelect";
